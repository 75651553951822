// 已绑定银行卡
import credit_billListDetail from "@/lib/data-service/haolv-default/consumer_credit_billListDetail.js";
import credit_flightBillOrderList from "@/lib/data-service/haolv-default/consumer_credit_flightBillOrderList.js";
import credit_flightBillRefundList from "@/lib/data-service/haolv-default/consumer_credit_flightBillRefundList.js";
import credit_trainOrderBillList from "@/lib/data-service/haolv-default/consumer_credit_trainOrderBillList.js";
import credit_trainRefundBillList from "@/lib/data-service/haolv-default/consumer_credit_trainRefundBillList.js";
import credit_hotelOrderList from "@/lib/data-service/haolv-default/consumer_credit_hotelOrderList.js";
import credit_hotelOutOrderList from "@/lib/data-service/haolv-default/consumer_credit_hotelOutOrderList.js";
import consumer_credit_carOrderBillList from "@/lib/data-service/haolv-default/consumer_credit_carOrderBillList.js";
import credit_billReportExcel from "@/lib/data-service/haolv-default/consumer_credit_billReportExcel.js";

export default {
    name: "HaveBillDetail",
    data () {
        return {
            activeLabel: "0",
            loading: false,
            form: {
                billId: 0,   //账单id
                businessType: 0,    //业务类型 1机票 2火车票 3酒店 4用车
                flowType: 0   //1.购物，2.还款，3.退款
            },
            billListDetail: {},
            labelList: ["机票订单", "机票退票订单", "火车票订单", "火车票退票订单", "酒店订单", "酒店退票订单","用车订单"],
            settleCycle: "",   //结算周期
            billList: [],
            tableList: [],
            url: ""
        };
    },
    computed: {},
    methods: {
        //初始化
        init0 () {
            this.billListDetail = {
                companyBankResult: {   //银行信息
                    invoiceTitle: "",   //开户名称
                    bankAccount: "",   //银行账号
                    openingBank: ""   //开户行
                },
                consumptionResult: [{   //消费信息
                    businessTypeText: "",   //应收款类别
                    orderAmount: 0,   //应收款金额
                    serviceAmount: 0,   //服务费
                    remark: ""   //备注
                }],
                creditBillResult: {   //账单信息
                    settleCycle: "",   //结算周期 账单周期
                    companyName: "",   //公司名称
                    billAmount: 0,   //账单总额
                    latestRefundDate: "",   //最晚结算时间
                    billStatusText: "",   //账单状态
                    actualBillRefundDate: ""   //实际结算时间
                }
            }
        },
        init1 () {
            this.billList = [{
                businessTypeText: "",   //业务类型
                clientName: "",   //客户名称
                evectionNo: "",   //出差申请单号
                changeOrderNo: "",   //改签申请单号
                orderNo: "",   //订单号
                bookingName: "",   //预订人
                mobile: "",   //预订人手机号码
                orderTime: "",   //预定时间
                totalPay: "",   //订单总额
                psgName: "",   //乘机人
                airlineName: "",   //航司
                flightNo: "",   //航班号
                ticketNumber: "",   //票号
                journeyText: "",   //行程
                cabin: "",   //舱位
                discount: 0,   //折扣
                depTimeText: "",   //起飞时间
                arrTimeText: "",   //到达时间
                flightNumber: 0,   //数量
                price: "",   //票价总额
                airportTax: "",   //税费
                serviceFee: "",   //服务费
                // insurance: "",   //保险
                insuranceTotalPrice: '', // 保险总额
                overproofStatus: "",   //是否超标
                reserveContent: ""   //超标原因
            }]
            this.tableList = [
                {
                    label: "业务类型",
                    prop: "businessTypeText"
                },
                {
                    label: "客户名称",
                    prop: "clientName"
                },
                {
                    label: "出差申请单号",
                    prop: "evectionNo"
                },
                {
                    label: "改签申请单号",
                    prop: "changeOrderNo"
                },
                {
                    label: "订单号",
                    prop: "orderNo"
                },
                {
                    label: "预订人",
                    prop: "bookingName"
                },
                {
                    label: "预订人手机号码",
                    prop: "mobile"
                },
                {
                    label: "预定时间",
                    prop: "orderTime"
                },
                {
                    label: "订单总额",
                    prop: "totalPay"
                },
                {
                    label: "乘机人",
                    prop: "psgName"
                },
                {
                    label: "航司",
                    prop: "airlineName"
                },
                {
                    label: "航班号",
                    prop: "flightNo"
                },
                {
                    label: "票号",
                    prop: "ticketNumber"
                },
                {
                    label: "行程",
                    prop: "journeyText"
                },
                {
                    label: "舱位",
                    prop: "cabin"
                },
                {
                    label: "折扣",
                    prop: "discount"
                },
                {
                    label: "起飞时间",
                    prop: "depTimeText"
                },
                {
                    label: "到达时间",
                    prop: "arrTimeText"
                },
                {
                    label: "数量",
                    prop: "flightNumber"
                },
                {
                    label: "票价总额",
                    prop: "price"
                },
                {
                    label: "税费",
                    prop: "airportTax"
                },
                {
                    label: "服务费",
                    prop: "serviceFee"
                },
                {
                    label: "保险总额",
                    prop: "insurance"
                },
                {
                    label: "是否超标",
                    prop: "overproofStatus"
                },
                {
                    label: "超标原因",
                    prop: "reserveContent"
                }
            ]
        },
        init2 () {
            this.billList = [{
                businessTypeText: "",   //业务类型
                clientName: "",   //客户名称
                evectionNo: "",   //出差申请单号
                refOrderApplyNo: "",   //退票申请单号
                orderNo: "",   //订单号
                refOrderNo: "",   //退票单号
                bookingName: "",   //预订人
                mobile: "",   //预订人手机号码
                airlineName: "",   //航司
                flightNo: "",   //航班号
                journeyText: "",   //行程
                cabin: "",   //舱位
                cabinRankText: "",   //舱等
                discount: 0,   //折扣
                psgName: "",   //乘机人
                refDate: "",   //退票时间
                refAmount: "",   //退款金额
                refundInsuranceTotalPrice: '', // 退保总费用
            }]
            this.tableList = [
                 {
                     label: "业务类型",
                     prop: "businessTypeText"
                 },
                 {
                     label: "客户名称",
                     prop: "clientName"
                 },
                 {
                     label: "出差申请单号",
                     prop: "evectionNo"
                 },
                 {
                     label: "退票申请单号",
                     prop: "refOrderApplyNo"
                 },
                 {
                     label: "订单号",
                     prop: "orderNo"
                 },
                 {
                     label: "退票单号",
                     prop: "refOrderNo"
                 },
                 {
                     label: "预订人",
                     prop: "bookingName"
                 },
                 {
                     label: "预订人手机号码",
                     prop: "mobile"
                 },
                 {
                     label: "航司",
                     prop: "airlineName"
                 },
                 {
                     label: "航班号",
                     prop: "flightNo"
                 },
                 {
                     label: "行程",
                     prop: "journeyText"
                 },
                 {
                     label: "舱位",
                     prop: "cabin"
                 },
                 {
                     label: "舱等",
                     prop: "cabinRankText"
                 },
                 {
                     label: "折扣",
                     prop: "discount"
                 },
                 {
                     label: "乘机人",
                     prop: "psgName"
                 },
                 {
                     label: "退票时间",
                     prop: "refDate"
                 },
                 {
                     label: "退款金额",
                     prop: "refAmount"
                 },
                {
                    label: "退保总额",
                    prop: "refundInsuranceTotalPrice"
                }
             ]
        },
        init3 () {
            this.billList = [{
                businessTypeStr: "",   //业务类型
                customerName: "",   //客户名称
                oaOrderNo: "",   //OA单号
                orderNo: "",   //订单号
                buyerName: "",   //预订人
                buyerPhone: "",   //预订人手机号码
                bookTime: "",   //预定时间
                passengerName: "",   //乘客
                journeyStr: "",   //行程
                seatName: "",   //坐席
                startTime: "",   //出行时间
                arriveTime: "",   //到达时间
                passengersNum: 0,   //人数
                orderAmount: 0,   //订单总额
                ticketAmount: 0,   //车票总额
                ticketFee: 0,   //开票费
                insuranceTotalPrice: "",   //保险总额
                overStandard: "",   //是否超标
                overStandardReasons: ""   //超标原因
            }]
                this.tableList = [
                    {
                        label: "业务类型",
                        prop: "businessTypeStr"
                    },
                    {
                        label: "客户名称",
                        prop: "customerName"
                    },
                    {
                        label: "OA单号",
                        prop: "oaOrderNo"
                    },
                    {
                        label: "订单号",
                        prop: "orderNo"
                    },
                    {
                        label: "预订人",
                        prop: "buyerName"
                    },
                    {
                        label: "预订人手机号码",
                        prop: "buyerPhone"
                    },
                    {
                        label: "预定时间",
                        prop: "bookTime"
                    },
                    {
                        label: "乘客",
                        prop: "passengerName"
                    },
                    {
                        label: "行程",
                        prop: "journeyStr"
                    },
                    {
                        label: "坐席",
                        prop: "seatName"
                    },
                    {
                        label: "出行时间",
                        prop: "startTime"
                    },
                    {
                        label: "到达时间",
                        prop: "arriveTime"
                    },
                    {
                        label: "人数",
                        prop: "passengersNum"
                    },
                    {
                        label: "订单总额",
                        prop: "orderAmount"
                    },
                    {
                        label: "车票总额",
                        prop: "ticketAmount"
                    },
                    {
                        label: "开票费",
                        prop: "ticketFee"
                    },
                    {
                        label: "保险总额",
                        prop: "insuranceTotalPrice"
                    },
                    {
                        label: "是否超标",
                        prop: "overStandard"
                    },
                    {
                        label: "超标原因",
                        prop: "overStandardReasons"
                    }
                ]
        },
        init4 () {
            this.billList = [{
                businessTypeStr: "",   //业务类型
                customerName: "",   //客户名称
                oaOrderNo: "",   //OA单号
                orderNo: "",   //订单号
                changeNo: "",   //改签订单号
                buyerName: "",   //预订人
                buyerPhone: "",   //预订人手机号码
                journeyStr: "",   //行程
                seatName: "",   //坐席
                passengerName: "",   //乘客
                refundTime: "",   //退票时间
                refundAmount: 0,   //退款金额
                refundInsuranceTotalPrice: 0   //退保总额
            }]
                this.tableList = [
                    {
                        label: "业务类型",
                        prop: "businessTypeStr"
                    },
                    {
                        label: "客户名称",
                        prop: "customerName"
                    },
                    {
                        label: "OA单号",
                        prop: "oaOrderNo"
                    },
                    {
                        label: "订单号",
                        prop: "orderNo"
                    },
                    {
                        label: "改签订单号",
                        prop: "changeNo"
                    },
                    {
                        label: "预订人",
                        prop: "buyerName"
                    },
                    {
                        label: "预订人手机号码",
                        prop: "buyerPhone"
                    },
                    {
                        label: "行程",
                        prop: "journeyStr"
                    },
                    {
                        label: "坐席",
                        prop: "seatName"
                    },
                    {
                        label: "乘客",
                        prop: "passengerName"
                    },
                    {
                        label: "退票时间",
                        prop: "refundTime"
                    },
                    {
                        label: "退款金额",
                        prop: "refundAmount"
                    },
                    {
                        label: "退保总额",
                        prop: "refundInsuranceTotalPrice"
                    }
                ]
        },
        init5 () {
            this.billList = [{
                businessType: "",   //业务类型
                companyName: "",   //客户名称
                oano: "",   //OA单号
                orderNo: "",   //订单号
                operatorName: "",   //预订人
                phone: "",   //预订人手机号码
                orderAmount: 0,   //订单总额
                cityName: "",   //城市
                hotelName: "",   //酒店名称
                roomTypeName: "",   //房型
                checkInName: "",   //入住人
                orderTime: "",   //预定时间
                checkInDate: "",   //入住时间
                checkOutDate: "",   //离店时间
                roomNumNights: "",   //间夜数
                overProofStatus: "",   //是否超标
                overProofInfo: ""   //超标原因
            }]
                this.tableList = [
                    {
                        label: "业务类型",
                        prop: "businessType"
                    },
                    {
                        label: "客户名称",
                        prop: "companyName"
                    },
                    {
                        label: "OA单号",
                        prop: "oano"
                    },
                    {
                        label: "订单号",
                        prop: "orderNo"
                    },
                    {
                        label: "预订人",
                        prop: "operatorName"
                    },
                    {
                        label: "预订人手机号码",
                        prop: "phone"
                    },
                    {
                        label: "订单总额",
                        prop: "orderAmount"
                    },
                    {
                        label: "城市",
                        prop: "cityName"
                    },
                    {
                        label: "酒店名称",
                        prop: "hotelName"
                    },
                    {
                        label: "房型",
                        prop: "roomTypeName"
                    },
                    {
                        label: "入住人",
                        prop: "checkInName"
                    },
                    {
                        label: "预定时间",
                        prop: "orderTime"
                    },
                    {
                        label: "入住时间",
                        prop: "checkInDate"
                    },
                    {
                        label: "离店时间",
                        prop: "checkOutDate"
                    },
                    {
                        label: "间夜数",
                        prop: "roomNumNights"
                    },
                    {
                        label: "是否超标",
                        prop: "overProofStatus"
                    },
                    {
                        label: "超标原因",
                        prop: "overProofInfo"
                    }
                ]
        },
        init6 () {
            this.billList = [{
                businessType: "",   //业务类型
                companyName: "",   //客户名称
                oano: "",   //OA单号
                orderNo: "",   //订单号
                operatorName: "",   //预订人
                phone: "",   //预订人手机号码
                cityName: "",   //城市
                hotelName: "",   //酒店名称
                roomTypeName: "",   //房型
                checkInName: "",   //入住人
                roomNumNights: "",   //间夜数
                cancelTime: "",   //退订时间
                totalAmount: 0   //退款金额
            }]
                this.tableList = [
                    {
                        label: "业务类型",
                        prop: "businessType"
                    },
                    {
                        label: "客户名称",
                        prop: "companyName"
                    },
                    {
                        label: "OA单号",
                        prop: "oano"
                    },
                    {
                        label: "订单号",
                        prop: "orderNo"
                    },
                    {
                        label: "预订人",
                        prop: "operatorName"
                    },
                    {
                        label: "预订人手机号码",
                        prop: "phone"
                    },
                    {
                        label: "城市",
                        prop: "cityName"
                    },
                    {
                        label: "酒店名称",
                        prop: "hotelName"
                    },
                    {
                        label: "房型",
                        prop: "roomTypeName"
                    },
                    {
                        label: "入住人",
                        prop: "checkInName"
                    },
                    {
                        label: "间夜数",
                        prop: "roomNumNights"
                    },
                    {
                        label: "退订时间",
                        prop: "cancelTime"
                    },
                    {
                        label: "退款金额",
                        prop: "totalAmount"
                    }
                ]
        },
        init7 () {
            this.billList = [{
                orderNo: "",//订单号
                carPolicyTypeText: "",//差标类型
                usageScenariosText: "",//业务类型
                carTypeText: "",//运力类型
                callPerson: "",//叫车人
                callPersonPhone: "",//联系电话
                startLocationName: "",//出发地点
                endLocationName: "",//到达地点
                mileage: "",//实际行驶公里数
                orderTime: "",//下单时间
                payTime: "",//结算时间
                startingFee: "",//起步费
                mileageFee: 0,//里程费
                otherFee: "",//其他费用
                cancelFee: "",//取消费
                serviceFee: "",//服务费
                orderAmount: "",//订单金额
                enterpriseAmount: "",//企业支付
                personAmount: "",//个人支付
                orderTotalAmount: "",//结算总金额
                payTypeText: "",//支付类型
                deptName: "",//部门
                feeAffiliationName: "",//费用归属
            }]
            this.tableList = [
                {
                    label: "订单号",
                    prop: "orderNo"
                },
                {
                    label: "差标类型",
                    prop: "carPolicyTypeText"
                },
                {
                    label: "业务类型",
                    prop: "usageScenariosText"
                },
                {
                    label: "运力类型",
                    prop: "carTypeText"
                },
                {
                    label: "叫车人",
                    prop: "callPerson"
                },
                {
                    label: "联系电话",
                    prop: "callPersonPhone"
                },
                {
                    label: "出发地点",
                    prop: "startLocationName"
                },
                {
                    label: "到达地点",
                    prop: "endLocationName"
                },
                {
                    label: "实际行驶公里数",
                    prop: "mileage"
                },
                {
                    label: "下单时间",
                    prop: "orderTime"
                },
                {
                    label: "结算时间",
                    prop: "payTime"
                },
                {
                    label: "起步费",
                    prop: "startingFee"
                },
                {
                    label: "里程费",
                    prop: "mileageFee"
                },
                {
                    label: "其他费用",
                    prop: "otherFee"
                },
                {
                    label: "取消费",
                    prop: "cancelFee"
                },
                {
                    label: "服务费",
                    prop: "serviceFee"
                },
                {
                    label: "订单金额",
                    prop: "orderAmount"
                },
                {
                    label: "企业支付",
                    prop: "enterpriseAmount"
                },
                {
                    label: "个人支付",
                    prop: "personAmount"
                },
                {
                    label: "结算总金额",
                    prop: "orderTotalAmount"
                },
                {
                    label: "支付类型",
                    prop: "payTypeText"
                },
                {
                    label: "部门",
                    prop: "deptName"
                },
                {
                    label: "费用归属",
                    prop: "feeAffiliationName"
                }
            ]
        },
        async getBillListDetail () {
            try {
                if (this.loading) return
                this.loading = true
                const res = await credit_billListDetail({
                    billId: this.form.billId
                })
                this.billListDetail = res.datas;
            } catch (error) {
                this.$message({
                    type: "error",
                    message: '获取总账单详情失败'
                })
            } finally {
                this.loading = false
            }
        },
        async getFlightBillList () {
            try {
                if (this.loading) return
                this.loading = true
                this.form.businessType = 1
                let res = {}
                if (this.activeLabel === "1"){
                    this.form.flowType = 1
                    res = await credit_flightBillOrderList(this.form)
                } else {
                    this.form.flowType = 3
                    res = await credit_flightBillRefundList(this.form)
                }
                this.settleCycle = res.datas.settleCycle
                this.billList = res.datas.billList
            } catch (error) {
                this.billList = []
                this.$message({
                    type: "error",
                    message: '获取机票账单详情失败'
                })
            } finally {
                this.loading = false
            }
        },
        async getTrainBillList () {
            try {
                if (this.loading) return
                this.loading = true
                this.form.businessType = 2
                let res = {}
                if (this.activeLabel === "3"){
                    this.form.flowType = 1
                    res = await credit_trainOrderBillList(this.form)
                } else {
                    this.form.flowType = 3
                    res = await credit_trainRefundBillList(this.form)
                }
                this.settleCycle = res.datas.settleCycle
                this.billList = res.datas.billList
            } catch (error) {
                this.billList = []
                this.$message({
                    type: "error",
                    message: '获取火车票账单详情失败'
                })
            } finally {
                this.loading = false
            }
        },
        async getHotelBillList () {
            try {
                if (this.loading) return
                this.loading = true
                this.form.businessType = 3
                let res = {}
                if (this.activeLabel === "5"){
                    this.form.flowType = 1
                    res = await credit_hotelOrderList(this.form)
                } else {
                    this.form.flowType = 3
                    res = await credit_hotelOutOrderList(this.form)
                }
                this.settleCycle = res.datas.settleCycle
                this.billList = res.datas.hotelBillResponses
            } catch (error) {
                this.billList = []
                this.$message({
                    type: "error",
                    message: '获取酒店账单详情失败'
                })
            } finally {
                this.loading = false
            }
        },
        async getCarBillList () {
            try {
                if (this.loading) return
                this.loading = true
                this.form.businessType = 4
                let res = {}
                res = await consumer_credit_carOrderBillList(this.form)
                this.billList = res.datas.carOrderBillDetails
            } catch (error) {
                this.billList = []
                this.$message({
                    type: "error",
                    message: '获取用车账单详情失败'
                })
            } finally {
                this.loading = false
            }
        },
        async downloadBillClick () {
            try {
                if (this.loading) return
                this.loading = true
                const res = await credit_billReportExcel({
                    billId: this.form.billId
                })
                this.url = res.msg
                const downloadBillLink = document.createElement("a")
                downloadBillLink.href = this.url
                downloadBillLink.click()
            } catch (error) {
                this.$message({
                    type: "error",
                    message: '下载账单失败'
                })
            } finally {
                this.loading = false
            }
        },
        returnClick () {
            this.$router.go(-1)
        }
    },
    created (){
        this.init0()
    },
    activated () {
        this.form.billId = Number(this.$route.query.billId)
        this.activeLabel = "0"
        this.getBillListDetail()
    },
    watch: {
        activeLabel: {
            handler (val) {
                switch (val) {
                    case "0":
                        this.init0()
                        this.getBillListDetail()
                        break;
                    case "1":
                        this.init1()
                        this.getFlightBillList()
                        break;
                    case "2":
                        this.init2()
                        this.getFlightBillList()
                        break;
                    case "3":
                        this.init3()
                        this.getTrainBillList()
                        break;
                    case "4":
                        this.init4()
                        this.getTrainBillList()
                        break;
                    case "5":
                        this.init5()
                        this.getHotelBillList()
                        break;
                    case "6":
                        this.init6()
                        this.getHotelBillList()
                        break;
                    case "7":
                        this.init7()
                        this.getCarBillList()
                        break;

                    default:
                        break;
                }
            }
        }
    }
};
